// extracted by mini-css-extract-plugin
export var ArtistDescription = "LadyPink-module--ArtistDescription--AuEgf";
export var ArtistInfos = "LadyPink-module--ArtistInfos--7zjtJ";
export var ButtonWrapper = "LadyPink-module--ButtonWrapper --HEubu";
export var CardWrapper = "LadyPink-module--CardWrapper--9aoZT";
export var CarrouselWrapper2 = "LadyPink-module--CarrouselWrapper2--3k2q1";
export var Citations = "LadyPink-module--Citations--m8dhR";
export var DescriptionWrapper = "LadyPink-module--DescriptionWrapper--GlH5y";
export var ImageWrapper = "LadyPink-module--ImageWrapper--1CsBp";
export var LinkWrapper = "LadyPink-module--LinkWrapper--u94BF";
export var MobileProtrait = "LadyPink-module--MobileProtrait--HEjCE";
export var More = "LadyPink-module--More--t9Z7O";
export var MoreButton = "LadyPink-module--MoreButton--uWvip";
export var NameWrapper = "LadyPink-module--NameWrapper---hwig";
export var PdpWrapper = "LadyPink-module--PdpWrapper--GXadQ";
export var PhotosWrapper = "LadyPink-module--PhotosWrapper--uwMel";
export var ProfilWrapper = "LadyPink-module--ProfilWrapper--qQOtE";
export var TitleWrapper = "LadyPink-module--TitleWrapper--2lbl9";
export var Wrapper = "LadyPink-module--Wrapper--AXGV4";