
import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./LadyPink.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import LadyPinkPng1 from "../../../../res/Photos site/Lady Pink/lady pink 3.png"
import LadyPinkPng2 from "../../../../res/Photos site/Lady Pink/lady-pink-4.jpg"
import LadyPinkPng3 from "../../../../res/Photos site/Lady Pink/Photo Lady Pink.jpg"
import Pdp from "../../../../res/LadyPink/portrait.jpg"
import Button from "./../../../../components/Button";
import { Helmet } from "react-helmet";

import PastPresent1 from "../../../../res/LadyPink/past-present-1.jpg"
import PastPresent2 from "../../../../res/LadyPink/past-present-2.jpg"
import PastPresent3 from "../../../../res/LadyPink/past-present-3.jpg"


const isBrowser = typeof window !== "undefined";
const infos ={
  path: "lady-pink",
  name: "Lady Pink",
  description:
  "Sandra Fabara, also known as Lady Pink was the first woman recognized as part of the New York Graffiti scene. Featured as the main character in the iconic movie Wild Style side by side with Lee Quiñones, she was one of the rare women actively participating in the world of Graffiti at the time. She created a number of diverse paintings on cars and walls in New York and got a degree at the High School of Art and Design which granted her access to numerous New York exhibitions and galleries. At 21 she attended her first solo show at the Philadelphia Moore College of Art and participated in several exhibitions dedicated to the movement at the beginning of the 1990’s, travelling around Europe. Lady Pink’s personal style fluctuated throughout her career, from old school to wild style and towards more personal approaches in her studio. Since 1985, Lady Pink has concentrated herself on canvas and on a minute and precise use of the spray can, with increasingly poignant subjects often surrounding feminism. Her work is part of the permanent collections of renowned institutions such as the Whitney Museum of Art, The Metropolitan Museum, the Brooklyn Museum, as well as others in Europe like the Groninger Museum in the Netherlands and the Walker Art Center in Minneapolis in the United States.",
  pdp: Pdp,
  alt_pdp: "Photo de profil de Lady Pink.",
  citations: [
    "« Quand j’ai commencé à peindre, dans les années 70, les femmes essayaient toujours de prouver par elles-mêmes qu’elles pouvaient faire tout ce que les hommes faisaient. Le mouvement féministe était de plus en plus fort et, en tant qu’adolescente, je pense que ça m’a influencé. Sans m’en rendre compte j’étais devenue une jeune féministe. Plus les mecs disaient « vous ne pouvez pas faire ça », plus je devais prouver qu’ils avaient tord. Je devais le faire pour toutes mes soeurs qui me regardaient…Nous défendons nos oeuvres avec nos poings et notre courage. Quand vous avez des gars qui vous manquent de respect, vous allez le devoir de leur donner une leçon, sinon ils vont continuer à vous marcher dessus. Je suis désolée, mais c’est la façon dont ça fonctionne la-bas, et ce n’est pas facile. Mais cela reflète également ce qu’est le monde de l’art en général : 80% d’hommes blancs. Alors, vous devez vous battre bec et ongles, crier et supporter, être entendue et être grande pour obtenir le respect »"
  ],
  photos: [
    { src: PastPresent1, name: "Lady Pink" },
    { src: PastPresent2, name: "Lady Pink" },
    { src: PastPresent3, name: "Lady Pink" },
  ],
  works: ["Works", "Works", "Works"],
  videos: ["Videos", "Videos", "Videos"],
};

const LadyPink = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
     <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>LADY PINK <h2 style={{paddingLeft: "16px"}}>(Sandra Fabara - Americane)</h2></h1>
          <h3 className={NameWrapper} >Née en 1964</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        <ul className={ArtistInfos}>
          <p style={{fontWeight: "bolder"}}>EDUCATION</p>
          <br />
          <p style={{fontWeight: "bold"}}>1982</p>
          <p>- High School of Art and Design, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1985</p>
          <p>- Moore College of Art, Philadelphia, USA.</p>
          <br />
          <br />
          <p style={{fontWeight: "bolder"}}>SOLO EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- Lady Pink: Graffiti Herstory, Museum of Graffiti. Miami, USA, March 5th - May 20th. </p>
          <br />
          <p style={{fontWeight: "bold"}}>2020</p>
          <p>- Figure, Foxy production, Lower east side, New York, NY, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2015</p>
          <p>- Respectfully Yours Lady Pink, Queens Museum (offsite). New York, USA, April 14th - August 14th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1984</p>
          <p>- Femmes Fatales, Moore College of Art. Philadelphia, USA, April 9th - May 10th.</p>
          <br />
          <p style={{fontWeight: "bolder"}}> GROUP EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2022</p>
          <p>- New York Street Style : création des années 80 - Ghost Galerie, Paris, juillet - septembre 2022.</p>
          <p>- Accrochage saisonnier, Ghost Galerie, Paris, France, Janvier-Juin 2022.</p>
          <p>- Expo Legends: Lady Pink et Speedy Graphito, Art Five Gallery, Marseille, France 2 - 17 Septembre 2022.</p>
          <div className={More} id="more">
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- Ghost DNA - exposition d’ouverture - opening exhibition, Ghost galerie, Paris, France, 7th July - September 4th.</p>
          <p>- New York in the 80s : Selections from the Barry Blinderman Collection, Western Exhibitions Gallery, Chicago, USA, January 8th - February 20th.</p>
          <p>- Writing The Futura, Basquiat and the Hip-Hop Generation, Museum of fine arts, Boston, Massachusetts, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2020</p>
          <p>- Speedy Graphito and Lady Pink, Art Five Galerie, Marseille, France, December 2nd - 17th.</p>
          <p>- Figura, Foxy Production Gallery, New York, USA, November 7th - December 20th.</p>
          <p>- Writing the Future, Basquiat and the Hip-Hop Generation, Museum of Fine Arts, Boston, Back Bay, Boston, USA, October 18th - July 25th (catalogue).</p>
          <br />
          <p style={{fontWeight: "bold"}}>2019</p>
          <p> - Beyond the Streets, New York, USA, June 21st - August 30th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2018</p>
          <p>- Swiss Institute Architecture and Design Series : 3rd Edition, Readymades Belong To Everyone, Swiss Institute, New York, USA, June 23rd – August 19th.</p>
          <p>- City as Canvas : New York City Graffiti from the 70s & 80s, Indianapolis Museum of Art, Newfields, Idaville, USA, October 7th, 2017 - January 28th, 2018.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2017</p>
          <p>- Purple Haze, Charlotte Jackson Fine Art, Santa Fe, New Mexico, January 20th - February 27th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2016</p>
          <p>- The Printed Room - Works Off Paper, SALTS, Switzerland, June 16th - July 22nd.</p>
          <p>- Code Mixing : From Concrete to Canvas, MACLA/Movimiento de Arte y Cultura Latino Americana, San José, USA, June 1st – August 14th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2014</p>
          <p>- City as Canvas, Museum of the City of New York, Harlem, New York, USA, February 4th - September 21st.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2012</p>
          <p>- Purple : Younity, Causey Contemporary, Brooklyn, New York, USA, October 19th - November 18th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- Sélections : Robert Morris, Rosemarie Trockel, Jenny Holzer & Lady Pink, Spruth Magers, Berlin, Germany, April 8th - May 28th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009 - 2010</p>
          <p>- Urban Archives : That Was Then This Is Now, Bronx Museum of the Arts, Bronx, New York, USA from October 5th, 2009 - March 9th, 2010.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2007 - 2008</p>
          <p>- Graffiti Art and Fashion, Phoenix Art Museum, Phoenix, USA, September 22nd, 2007 - February 24th, 2008.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2004 - 2005</p>
          <p>- East Village USA: Then and Now - The Art of Graffiti, New Museum. New York, USA, December 9th 2004 - March 19th 2005.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2000</p>
          <p>- Mural Installation, La Sirena Astoria, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1999</p>
          <p>- Welcome - Mural Installation, Astoria, New York, USA.</p>
          <p>- Mural Installation, Lindenwood Diner Queens, New York, USA.</p>
          <p>- Painted set for Madonna photo shoot, David LaChapelle’s studio, New York, USA.</p>
          <p>- New York Views, Museum of New York, New York, USA.</p>
          <p>- Urban Mythologies, Bronx Museum of Art, New York, USA.</p>
          <p>- Quick and company, Groninger Museum, Groningen, The Netherlands.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1998</p>
          <p>- The Writing on the Wall, Herning Kunstmuseum, Copenhagen, Denmark.</p>
          <p>- Inner City, La Casa D’Arte, Milan, Italy.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1997</p>
          <p>- Bullet Space, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1996</p>
          <p>- Livestock Gallery, New York, USA.</p>
          <p>- Do you See What I see, Thread Waxing Space, New York, USA.</p>
          <p>- Tribu Universal, Thoughtforms Underground, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1995</p>
          <p>- Just Above Ground/Off the Wall, AC-BAW Center for the Arts, Mount Vernon, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1994</p>
          <p>- Multiple Flavors, SpaceTime Light Gallery, New York, USA.</p>
          <p>- Urban Expressionism, Hudson Guild Gallery, New York, USA.</p>
          <p>- DYAD, 57 Grand Street Brooklyn, USA.</p>
          <p>- Sixth Congress Gallery, Tucson, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1993</p>
          <p>- Still Graffiti, Librije Hedendaagse, Zwolle, The Netherlands, August 28th - September 26th.</p>
          <p>- Pat Hearn Gallery, New York, USA.</p>
          <p>- Coming from the Subway, Groninger Museum, Groningen, The Netherlands, October 4th 1992 - January 10th 1993.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1992</p>
          <p>- Self Portraits, Kleefeld Perry Gallery, New York, USA.</p>
          <p>- Graffiti III, Colleen Groco Gallery, New York, USA.</p>
          <p>- Malcolm X : The Portrait of a Legacy, Aaron Davis Hall/City College, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1980</p>
          <p>- GAS: Graffiti Art Success for America, curated by John Matos CRASH, Fashion Moda Gallery. New York, USA, October.</p>
          <br />
          <br />
          <p style={{fontWeight: "bolder"}}>PERMANENT COLLECTIONS</p>
          <br />
          <p>- Whitney Museum of Art, New York, USA.</p>
          <p>- The Metropolitan Museum of Art, New York, USA.</p>
          <p>- Brooklyn Museum, New York, USA.</p>
          <p>- Groninger Museum of Holland, Groningen, Netherlands.</p>
          <p id="end">- The Walker Art Center, Minneapolis, USA.</p>
          </div>
        </ul>
            <button className={MoreButton} id="moreBtn" onClick={() => {
                 var sel = document.querySelector('#more');
                 var selBtn = document.querySelector('#moreBtn');
                 console.log("in there");

                
                  if (sel.style.display === "none") {
                    sel.style.display = "inline";
                    selBtn.innerHTML = "Read more";
                  } else {
                    sel.style.display = "none";
                    selBtn.innerHTML = "Read less";
                }
                 
              }}
               >Read more</button>
        </div>
      </div>
      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/en/contactez-nous";
                }
              }
            }
            >
              Contact-us
            </Button>
        </div>
    </div>
  );
};

export default LadyPink;